
import { defineComponent, ref } from 'vue'
import CampaignsSentTable from '@/components/pages/campaigns/details/sent/CampaignsSentTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import { useFilters } from '@/compositions/filters'
import { campaignsResponses } from '@/definitions/shared/filters/filtersList/data'
import { formatDate } from '@/services/dateTimeService'
import CampaignsResponsesFilter from '@/components/pages/campaigns/details/responses/CampaignsResponsesFilter.vue'
import TmEmptyState from '@/components/shared/TmEmptyState.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    CampaignsResponsesFilter,
    CampaignsSentTable,
    TmEmptyState,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const expand = ref(false)
    const search = ref()

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Full name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Message', value: 'message', expandPossible: true },
      { text: 'Response date', value: 'date', format: (val: string) => formatDate(val) },
    ])
    const tableItems = ref(getTableData('campaignsEmailSentMessages'))
    const { filters, isDefault, reset } = useFilters(campaignsResponses)

    return {
      isEmptyMode,
      tableItems,
      filters,
      isDefault,
      reset,
      search,
      expand,
      tableHeaders,
    }
  },
})
