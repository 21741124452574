
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import type { FiltersListType } from '@/definitions/shared/filters/types'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TopFilter,
    DropdownFilterRadio,
  },
  props: {
    filters: {
      type: Object as PropType<FiltersListType>,
    },
  },
})
