import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_campaigns_sent_actions = _resolveComponent("campaigns-sent-actions")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-name": _withCtx((props) => [
      _createVNode(_component_tm_person, {
        name: props.row.name.fullName,
        "avatar-url": props.row.name.avatar,
        "avatar-color": props.row.name.avatarColor,
        "avatar-size": "small"
      }, null, 8, ["name", "avatar-url", "avatar-color"])
    ]),
    "body-cell-status": _withCtx((props) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(props.row.status)), null, 16)
    ]),
    "body-cell-phone": _withCtx((props) => [
      _createVNode(_component_tm_country, {
        country: {
          id: props.row.country.id,
          name: props.row.phone,
        },
        size: "xSmall"
      }, null, 8, ["country"])
    ]),
    "body-cell-actions": _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_campaigns_sent_actions)
      ])
    ]),
    _: 3
  }))
}